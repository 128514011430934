@import "../assets/Scss/variables.scss";

.notificationWrapper {
  position: fixed;
  top: 62px;
  right: 20px;
  max-height: calc(100vh - 40px);
  z-index: 1000;
  background-color: $white;
  padding: 10px;
  overflow-y: scroll;
  max-height: 80vh;

  .navLinkButton {
    width: 100%;
    display: inline-block;
    text-align: center;
    background-color: $white;
    color: $dark;
    padding: 2px;
    border: none;
    cursor: pointer;
    border-top: 2px solid $blue;
  }

  .navLinkButton.active {
    background-color: $blue;
    color: $white;
    font-weight: bold;
  }

  .notificationUser {
    font-size: small;
    text-align: left;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }

  .notificationText {
    font-size: x-small;
    text-align: left;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }

  .notificationDate {
    font-size: x-small;
    text-align: end;
  }
}
