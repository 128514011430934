.Skeleton {
  width: 100%;
  height: 50px;
  background: #efefef;
  position: relative;
  overflow: hidden;
  border-radius: 4px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 80%;
  }
}
