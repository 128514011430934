@import "../assets/Scss/variables.scss";

.toasterWrapper {
  .toaster {
    top: 20px;
    right: 20px;
    position: fixed;
    min-width: 200px;
    z-index: 10000;
    background: $blue;
    border: none;
    color: $white;

    .toasterHeader {
      border: none;
      display: flex;
      font-weight: bolder;
      align-items: center;
      background-color: transparent;
      color: $white;
    }

    .toasterBody {
      color: $white;
    }
  }
}
