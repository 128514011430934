html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
img,
ol,
ul,
li,
fieldset,
form,
label,
article,
aside,
canvas,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  vertical-align: baseline;
  background: transparent;
  font-size: 100%;
}

a,
img {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: top;
  background: none;
  text-decoration: none;
}

a,
button,
input[type="submit"] {
  background: none;
  cursor: pointer;
}

form {
  box-sizing: border-box;
}

input,
select,
button,
textarea {
  display: block;
  margin: 0;
  padding: 0;
  outline: none;
  border: 0;
  box-sizing: border-box;
  font: var(--font);
  text-rendering: geometricprecision;
  letter-spacing: -0.1px;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

input:-webkit-autofill {
  box-shadow: 200px 200px 100px var(--color-bg) inset;
}

article,
aside,
figcaption,
time,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
  box-sizing: border-box;
}

html,
body {
  background-color: transparent;
  margin: 0;
  padding: 0;
  font: var(--font-m);
  text-rendering: geometricprecision;
  letter-spacing: -0.1px;
}

div {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
