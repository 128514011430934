.TopBar {
  .Bell {
    position: relative;
    cursor: pointer;

    .Badge {
      width: 16px;
      height: 16px;
      background: red;
      border-radius: 8px;
      position: absolute;
      top: -5px;
      right: -5px;
      font-size: 8px;
      line-height: 16px;
      color: white;
      text-align: center;
    }
  }
}
