.Img {
  flex-shrink: 0;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.Placeholder {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: solid 1px var(--color-text-tertiary);
  color: var(--color-text-tertiary);
}
