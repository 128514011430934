@import "../assets/Scss/variables.scss";

.fillBtn,
.OutlineBtn {
  font-weight: bold !important;
  border-radius: $border-radius-20 !important;
  border: 2px solid transparent !important;
  position: relative;
}
.fillBtn {
  background-color: $blue !important;
  color: $white !important;
}
.OutlineBtn {
  background-color: $white !important;
  border: 2px solid $blue !important;
  color: $dark !important;
}
