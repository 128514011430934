@import "variables";

@mixin bgProperties {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bgProperties {
  @include bgProperties;
}

.text-primary {
  color: $blue !important;
}

.text-primary-dark {
  color: $dark-blue !important;
}

.text-custom-dark {
  color: $dark !important;
}

.errorField {
  margin-bottom: 0 !important;
  color: $orange;
  font-size: $font-14;
}

.bg-blue {
  background-color: $blue;
}

.profile-edit-Icon {
  position: absolute;
  width: 40px;
  height: 40px;
  z-index: 99;
  border-radius: 100%;
  right: 0;
  bottom: 6px;
}

.react-tel-input,
.select,
.form-control,
.form-control-lg {
  &:focus,
  &:focus-visible {
    box-shadow: none !important;
    outline: none !important;
  }
}

.customDropdownRadius {
  border-radius: 14px !important;
}

.customSelect {
  select {
    padding: 15px 15px !important;
    color: $black !important;
    font-size: 16px !important;
    option {
      &:hover {
        background: $blue !important;
        color: $dark-blue !important;
      }
    }
  }
}

.react-tel-input {
  border-radius: 14px;
  .form-control-lg {
    font-size: 16px !important;
    border-radius: 14px !important;
    border: 1px solid #dee2e6;
    padding-left: 50px !important;
    &:focus {
      outline: none !important;
    }
  }
  .flag-dropdown {
    background-color: $content-bg !important;
    border-top-left-radius: 14px !important;
    border-bottom-left-radius: 14px !important;
    border: none !important;
    .selected-flag {
      border-top-left-radius: 14px !important;
      border-bottom-left-radius: 14px !important;
      &:hover {
        border-top-left-radius: 14px !important;
        border-bottom-left-radius: 14px !important;
      }
    }
  }
}

.cursorPointer {
  cursor: pointer;
}

.no-wrap {
  white-space: nowrap;
}

.fetchDetailTag {
  background-color: $blue;
  color: $white;
  font-size: 10px;
  padding: 2px 4px;
  border-radius: 10px;
}

.decorationNone {
  text-decoration: none;
  color: $black;
}

.bg-highlighted {
  background-color: $sea-blue;
}

.commission-record-date-range-picker {
  position: absolute !important;
  right: 0;
  top: 25px;
  z-index: 99;
}

.commission-record-date-range-picker .rdrCalendarWrapper {
  background-color: $light-gray !important;
}

// @mixin bgProperties {
//   background-position: center center;
//   background-repeat: no-repeat;
//   background-size: cover;
// }
// .btn.disabled {
//   background-color: $yellow-blur !important;
//   opacity: 1 !important;
// }
// .bgProperties {
//   @include bgProperties;
// }

// .bgNotification {
//   background-color: $yellow-blur !important;
// }
// .small {
//   font-size: 10pt !important;
// }
// .bgBlur {
//   background-color: $blur-color !important;
//   border-bottom-left-radius: 14px !important;
//   border-bottom-right-radius: 14px !important;
// }
// .customBgDark {
//   background: $dark;
// }

// .font14 {
//   font-size: $font-14 !important;
// }

// .w-20 {
//   width: 20% !important;
// }

// .middle-subscription-card {
//   transition: transform 0.3s ease;
//   transform: scale(1.1);
//   z-index: 1;
// }

// .custom-badge {
//   font-size: 14px !important;
//   background-color: $yellow !important;
//   border-radius: 25px !important;
// }

// .textYellow {
//   color: $yellow !important;
// }

// .textOrange {
//   color: $orange !important;
// }

// .exerciseSubcategoryWrapper {
//   background-color: $yellow;
//   border-radius: 14px;
// }

// .bgYellow {
//   background-color: $yellow !important;
// }

// .textGrey {
//   color: $text-muted !important;
// }

// .textParrotGreen {
//   color: $parrot-green !important;
// }
// .contactUsCard,
// .contentCard {
//   margin-top: 12vh;
//   overflow-y: scroll;
//   height: 88vh !important;
//   border: none !important;
// }
// .contactUsCard {
//   margin-top: 0px !important;
// }
// .contentCardPadding {
//   padding-top: 12vh;
// }

// .allServiceProvidersFloatingBtnRight {
//   position: absolute !important;
//   bottom: 35px;
//   right: 25px;
//   z-index: 1;
// }

// .allServiceProvidersFloatingBtnLeft {
//   position: absolute !important;
//   bottom: 35px;
//   left: 25px;
//   z-index: 1;
// }

// .cursorPointer {
//   cursor: pointer;
// }

// .BorderRadius {
//   box-shadow: $card-box-shadow;
//   border: none !important;
// }
// .customDropdownRadius,
// .BorderYellow,
// .onlyBorderRadius,
// .BorderRadius,
// .subscriptionDiv {
//   border-radius: 14px !important;
// }

// .ImgBorder {
//   border-top-right-radius: 14px !important;
//   border-top-left-radius: 14px !important;
// }
// .yellowBorder,
// .BorderYellow {
//   border: 1px solid $yellow !important;
// }

// a,
// .a {
//   text-decoration: none !important;
//   outline: none !important;
// }

// Input {
//   &:focus {
//     border: 1px solid $yellow !important;
//     outline: 1px solid $yellow !important;
//   }
// }

// .profileEditIcon {
//   &:hover {
//     fill: $yellow;
//   }
// }

// Input::-webkit-input-placeholder {
//   color: $placeholder-color;
// }
// .dropdown-menu {
//   padding: 3px 3px !important;
// }

// .dropdown-item {
//   &:active,
//   &:hover,
//   &:focus,
//   &::selection {
//     background-color: $yellow !important;
//     color: $black;
//     outline: none;
//     border-radius: 6px;
//   }
// }
// .customSelect {
//   select {
//     color: $black !important;
//     font-size: 17px !important;
//     option {
//       &:hover {
//         background: $yellow !important;
//         color: $black !important;
//       }
//     }
//   }
// }

// .UncontrolledDropdown {
//   .DropdownMenu {
//     .DropdownItem {
//       padding: 8px;
//       &:hover,
//       &:active {
//         background: $yellow;
//       }
//     }
//   }
// }

// .react-tel-input,
// .genderBtn,
// .paymentMethodBtn,
// .select,
// .form-control,
// .form-control-lg {
//   &:focus,
//   &:focus-visible {
//     border: 1px solid $parrot-green !important;
//     box-shadow: none !important;
//     outline: none !important;
//   }
// }
// .react-tel-input {
//   border-radius: 14px;
//   .form-control-lg {
//     border-radius: 14px !important;
//     border: 1px solid #dee2e6;
//     padding-left: 50px !important;
//     &:focus {
//       outline: none !important;
//     }
//   }
//   .flag-dropdown {
//     background-color: $content-bg !important;
//     border-top-left-radius: 14px !important;
//     border-bottom-left-radius: 14px !important;
//     border: none !important;
//     .selected-flag {
//       border-top-left-radius: 14px !important;
//       border-bottom-left-radius: 14px !important;
//       &:hover {
//         border-top-left-radius: 14px !important;
//         border-bottom-left-radius: 14px !important;
//       }
//     }
//   }
// }

// .form-check-input:checked::before {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   content: "\2713 ";
//   color: $parrot-green !important;
//   font-weight: bold;
//   margin-top: -13px;
//   margin-left: 1px;
// }

// .form-check-input:checked {
//   &focus {
//     box-shadow: none !important;
//     background-color: $yellow-blur !important;
//     color: $parrot-green !important;
//   }
//   background-color: $yellow-blur !important;
//   border: 1px solid $parrot-green !important;
//   color: $parrot-green !important;
// }

// .buttonBoxShadow {
//   box-shadow: 0px 2px 14px 0px $button-box-shadow;
// }

// .errorField {
//   margin-bottom: 0 !important;
//   color: $orange;
//   font-size: $font-14;
// }

// .customPhoneInput {
//   font-size: $font-14 !important;
// }

// .customPaddingY {
//   padding: 12px 0px !important;
// }

// .customSpacing {
//   letter-spacing: -0.8px;
//   line-height: 24px;
//   word-spacing: 2px;
// }

// .CategoryCard {
//   .CategoryCardHeader {
//     background-color: $light-yellow !important;
//     height: 100% !important;
//   }
// }

// .CameraImg {
//   position: absolute;
//   width: 40px;
//   height: 40px;
//   z-index: 99;
//   border-radius: 100%;
//   right: 0;
//   bottom: 6px;
//   border: 2px solid white;
//   background-color: rgba(24, 24, 24, 0.99);
// }

// .tableBodyWrapperPagination {
//   max-height: 65vh;
//   overflow-y: scroll;
// }
// .chatCardFooterHeight {
//   max-height: 62vh;
//   overflow-y: auto;
// }
// .adminDashBoardScrolling {
//   max-height: 81vh;
//   overflow-y: scroll;
//   scrollbar-color: green;
// }

// .tableBodyWrapperPagination::-webkit-scrollbar,
// .adminDashBoardScrolling::-webkit-scrollbar {
//   width: 0;
// }

// .genderBtn {
//   .selected {
//     color: $parrot-green !important;
//     border: 2px solid $parrot-green !important ;
//   }
// }

// .paymentMethodBtn {
//   .selected {
//     color: $parrot-green !important;
//     border: 2px solid $parrot-green !important ;
//   }
// }
// .rmsc {
//   .dropdown-container {
//     padding: 8px 10px !important;
//     border-radius: 14px !important;
//     font-size: 14px !important;
//     &:focus-within {
//       border: 1px solid $parrot-green !important;
//       box-shadow: none !important;
//     }
//   }
// }
// .arabicBorderHover,
// .borderHover {
//   position: relative;
// }

// .arabicBorderHover::before,
// .borderHover::before {
//   content: "";
//   transform: scaleX(0);
//   transform-origin: bottom right;
//   height: 2px;
//   background-color: $yellow;
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   margin-top: -100px;
//   transition: transform 0.9s ease;
// }

// .arabicBorderHover:hover::before,
// .borderHover:hover::before {
//   transform: scaleX(1);
//   transform-origin: bottom left;
// }

// .swiper-pagination-bullet {
//   display: none !important;
// }

// .currentlyWorkingBtn {
//   .selected {
//     color: $parrot-green !important;
//     border: 2px solid $parrot-green !important ;
//   }
// }
// .selectField {
//   padding-top: 12px !important;
//   padding-bottom: 12px !important;
// }

// .text-black-custom {
//   color: $black !important;
// }

// .rtl {
//   direction: rtl;
// }
// .ltr {
//   direction: ltr;
// }

// ::-webkit-scrollbar {
//   width: 5px;
//   background-color: transparent !important;
// }

// ::-webkit-scrollbar-thumb {
//   background: transparent !important;
//   border-radius: 5px;
// }

// ::-webkit-scrollbar-track {
//   background: transparent !important;
// }

 .remove-arrow {
   -webkit-appearance: none;
   -moz-appearance: textfield;
   appearance: textfield;
 }

 .remove-arrow::-webkit-inner-spin-button,
 .remove-arrow::-webkit-outer-spin-button {
   -webkit-appearance: none;
   margin: 0;
 }

 .remove-arrow::-ms-clear {
   display: none;
 }

// .shimmer-thumbnail {
//   height: 38vh !important;
//   border-radius: 14px;
// }

// .customLetterSpacing {
//   letter-spacing: -1px;
// }

// .signInCol {
//   background-color: white;
// }

// .menu-open {
//   overflow: hidden !important;
// }

// .activeScroll {
//   overflow: scroll !important;
// }

// @media (max-width: 767px) {
//   .signInCol {
//     background: transparent !important;
//   }
//   .customPadding {
//     padding-top: 40px !important;
//     padding-bottom: 40px !important;
//   }
// }
// @mixin customCard {
//   display: flex;
//   &:hover {
//     .descriptionDiv {
//       display: block;
//       margin-bottom: 70px;
//     }
//     .divider {
//       width: 70px;
//       height: 3px;
//       text-align: center;
//       margin: 0 auto;
//       background-color: $parrot-green;
//     }
//     .customCardFooter {
//       height: 396px;
//       opacity: 1;
//     }
//   }
// }
// .customCard {
//   color: $white;
//   margin: 0px 15px;
//   position: relative;
//   width: 90% !important;
//   background-size: cover;
//   background-position: center;
//   min-height: 400px !important;
//   border: $text-muted 2px solid;
//   border-radius: 14px !important;

//   .customCardFooter {
//     left: 0;
//     bottom: 0;
//     opacity: 0;
//     width: 100%;
//     height: 4rem;
//     display: flex;
//     position: absolute;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     border-radius: 14px !important;
//     transition: all 0.4s linear 0.1s;
//     background-color: rgba(34, 29, 29, 0.8);

//     .descriptionDiv {
//       display: none;
//     }
//   }

//   &:after {
//     height: 85%;
//     background: $parrot-green;
//     z-index: -5;
//     content: " ";
//     position: absolute;
//     left: -12px;
//     right: -12px;
//     top: 0;
//     bottom: 0;
//     margin: auto;
//     border-radius: 10px;
//     margin-left: 12px;
//     margin-right: 12px;
//     transition: margin-left 0.5s ease-in-out, margin-right 0.5s ease-in-out,
//       height 0.5s ease-in-out, background 0.5s ease-in-out;
//   }

//   &:hover {
//     &:after {
//       margin-left: 0;
//       margin-right: 0;
//       height: 85%;
//       background: $parrot-green;
//       z-index: -5;
//     }
//   }

//   transition: all 3s ease-in-out;
// }
// .optWidthForEng {
//   width: 100%;
// }
// .optWidthForArb {
//   width: 90%;
// }
// .tableResponsiveWidth {
//   max-width: 100%;
// }
// @media screen and (max-width: 40em) {
//   .tableResponsiveWidth {
//     max-width: 130px;
//   }
//   .responsiveTable {
//     td {
//       .tdBefore {
//         position: absolute;
//         display: block;
//         left: 0.2em !important;
//         width: calc(54% - 20px) !important;
//         white-space: pre-wrap;
//         overflow-wrap: break-word;
//         text-align: left !important;
//         font-weight: 600;
//       }
//     }
//   }
// }
