@import "../shared/assets/Scss/variables";
@import "../shared/assets/Scss/globalStyleSheet";
@import "./styles/vars";
@import "./styles/reset";

//===================sidebar======================//
.sidebar-content {
  height: 100vh;
  z-index: 999;
  color: $white;
  background-color: $blue !important;
}

.openSidebar {
  margin-left: 260px;
}

.transition {
  transition: 0.15s all ease;
}

.Menu ul li {
  overflow: hidden !important;
}

.ps-submenu-content {
  background-color: $blue !important;
}

.ps-menu-button {
  height: 50px !important;
  padding: 5px 10px !important;
  display: flex !important;
  align-items: center !important;
}

.Menu .subMenuItem {
  padding: 0 20px !important;
}

.Menu .activeSubMenuItem {
  background-color: $white;
  padding: 0 20px !important;
  color: $sea-blue !important;
}

.Menu .activeMenuItem {
  li {
    background-color: $white;
    color: $sea-blue !important;
  }
}

.ps-menu-button:hover {
  color: $sea-blue !important;
  background: transparent !important;
}

//===============navbar===================//
.navbar {
  background-color: $light-gray;
}

//===============dateRangePicker==========//
.rdrDefinedRangesWrapper {
  display: none !important;
}
