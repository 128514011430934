:root {
  --font-family: "Open Sans", sans-serif;
  --font-xs: 500 12px / 18px var(--font-family);
  --font-s: 500 14px / 20px var(--font-family);
  --font-s-semi-bold: 600 14px / 20px var(--font-family);
  --font-s-bold: 700 14px / 20px var(--font-family);
  --font-m: 500 16px / 24px var(--font-family);
  --font-m-semi-bold: 600 16px / 24px var(--font-family);
  --font-m-bold: 700 16px / 24px var(--font-family);
  --font-l: 500 18px / 28px var(--font-family);
  --font-xl: 500 20px / 30px var(--font-family);
  --font-xxl: 500 30px / 38px var(--font-family);
  --font-xxxl: 500 36px / 44px var(--font-family);

  --color-bg: #fff;
  --color-text-primary: #0F0F0F;
  --color-text-secondary: #292929;
  --color-text-tertiary: #525252;
  --red: #F04438;
  --color-border: #E5E5E5;
  --blue: #1a83e4;
  --navy: #2e3e80;
  --orange: #ff5f00;

  // borders
  --color-primary-border: #E5E5E5;
  --color-primary-border-error: #FDA29B;

  // input
  --color-input-bg-disabled: #F5F5F5;
  --color-input-placeholder: #737373;
  --color-input-label: #192862;
  --color-input-border: #E5E5E5;
  --color-input-border-hover: #979797;
  --color-input-border-focus: #b9b9b9;
  --color-input-border-disabled: #E5E5E5;
  --color-input-border-has-error: #c82333;
  --color-input-text-disabled: #A3A3A3;
  --color-input-box-shadow: #CFCFCF33;
  --color-input-box-shadow-has-error: #fa70661f;
  --input-border-radius: 14px;

  // table
  --color-header-background: #fafafa;
  --color-row-background: #fafafa;

  // buttons
  --color-button-bg: #ffffff;
  --color-button-bg-active: #efefef;
  --color-button-bg-disabled: #F5F5F5;
  --color-button-border: #D6D6D6;
  --color-button-text: #292929;
  --color-button-primary-bg: #E6F4D7;
  --color-button-primary-bg-hover: #CEEAB0;
  --color-button-primary-bg-disabled: #F5FBEE;
  --color-button-primary-text: #292929;
  --color-button-primary-text-disabled: #A3A3A3;
  --color-button-auth-bg: #E6F4D7;
  --color-button-auth-bg-hover: #E6F4D7;
  --color-button-auth-text: #292929;
  --color-button-secondary-bg: #FFF;
  --color-dropdown-bg: #FEF0C7;
  --color-button-dropdown-border: #737373;
  --color-dropdown-box-shadow: #0000001A;
  --color-delete-button-bg: #F5F5F5;
  --color-delete-button-bg-hover: #FEE4E2;

  // transition
  --transition-default: all 0.3s cubic-bezier(0.166, 0, 0.66, 1);
  --transition-button: background-color 0.3s cubic-bezier(0.166, 0, 0.66, 1);
  --transition-input: border-color 0.3s cubic-bezier(0.166, 0, 0.66, 1);

  --modal-z-index: 2;
  --modal-overlay-z-index: 1;
}
